<template>
    <div class="fix-center">
        <div class="content content_block">
            <h1>Safe & Secure</h1>
            <p>Screx ensures a secure, compliant, and user-focused DeFi experience, providing a trustworthy platform for confident participation. Screx is the future of decentralized finance, empowering you to take full control of your digital assets. Join us on this journey!</p>
        </div>
        <div class="footer_info">
            <div class="item">
                <img src="@/assets/logo_text_screx.svg" alt="screx">
            </div>
            <div class="item">
                <p>Copyright © 2024 Screx. All Rights Reserved.</p>
            </div>
            <div class="icons_cta item">
                <a href="https://www.linkedin.com/company/screx/" target="_blank">
                    <img src="@/assets/linkedin.svg" alt="twitter" />
                </a>
                <a href="https://x.com/screx_com" target="_blank">
                    <img src="@/assets/x.svg" alt="x" />
                </a> 
                <a href="https://t.me/Screx_com" target="_blank">
                    <img src="@/assets/telegram.svg" alt="telegram" />
                </a>
                <a href="https://t.me/Screx_community" target="_blank">
                    <img src="@/assets/telegram.svg" alt="telegram" />
                </a>
                <a href="https://linktr.ee/screx.com" target="_blank">
                    <img src="@/assets/soc.svg" alt="linktree" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SafuView',
}
</script>

<style scoped>
.right-links .icons_cta {
    display: none!important;
}
.footer_info {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 25px 10px 0;
    display: flex;
    align-items: center;
}
.footer_info .item {
    width: 50%;
}
.footer_info .item:first-child, .footer_info .item:last-child {
    width:25%;
}
.footer_info .item:first-child {
    text-align: left;
    display:flex;
}
.footer_info .item:last-child {
    text-align: right;
    justify-content: end;
}
.footer_info p {
    text-align: center;
    font-size: 20px;
}
.content_block {
    position: relative;
    width: 100%;
}
.content_block::before {
    content: '';
    background-image: url('@/assets/safe.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left:-130px;
}
h1 {
    font-size: 70px;
    font-weight: 600;
}
h1 span.strike {
    position: relative;
}
h1 span.strike::after {
    content:'';
    display: inline-block;
    width: 100%;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 60%;
    left: 0;
}
p {
    font-size: 24px;
    color: #fff;
    line-height: 1.4em;
    text-align: left;
}

@media (max-width: 980px) {
    .content_block::before {
        top:0;
        left:0;
        margin-left: -30px;
        margin-top: -50px;
    }
    h1 {
        margin-bottom: 40px;
        font-size: 50px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }
    p {
        font-size: 21px;
    }
    .footer_info {
        display:flex;
        flex-wrap: wrap;
        padding:0 25px 0 0;
    }
    .footer_info .item {
        order:3;
        width: 100%;
    }
    .footer_info .item:first-child {
        order:1;
        width: 50%;
    }
    .footer_info .item:last-child {
        order:2;
        width: 50%;
    }
    .footer_info .item:nth-child(2) {
        text-align: center;
        font-size: 16px;
        margin-bottom: 15px;
        padding-left:25px;
    }
    .footer_info .item:nth-child(2) p {
        font-size: 16px;
    }
    body .fix-center .content, body .fix-center .content_block {
     margin-top: 150px;
    }
}
@media(max-width: 700px) {
    .content_block::before {
        margin-left: -30px;
        margin-top: -25px;
    }
}
</style>
<template>
    <div class="fix-center">
        <div class="content content_block">
            <a href="https://screx.com"  class="btn_text btn_back btn_back_mobile">back to main</a>
            <h1>Thanks for <span class="strike">your patience!</span></h1>
            <div class="promo_text promo_desktop">
                <span class="stroke">Good things take time, and we're thrilled to</span> <span class="stroke"> bring</span> something <span class="stroke">amazing your way very soon.</span>
            </div>
            <div class="promo_text promo_mobile">
                <span class="stroke">Good things take time, and</span><br><span class="stroke">we're thrilled to bring</span> something<br><span class="stroke">amazing your way very soon.</span>
            </div>
            <div class="form_save">
                <input type="email" v-model="user.email" :placeholder="isFocused ? '' : 'name@email.com'" @focus="isFocused = true" @blur="isFocused = false">
                <button id="checkListNews" @click="saveEmail2" :disabled="loading">
                    {{ loading ? 'Processing...' : 'Join waitlist' }}
                </button>
            </div>
            <div v-if="message" :class="['alert', message.type]">
                {{ message.text }}
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import axios from 'axios';

export default {
    name: 'DappView',
    setup() {
        const user = reactive({ email: '' });
        const isFocused = ref(false);
        const loading = ref(false);
        const message = reactive({ text: '', type: '' });

        const saveEmail2 = async () => {
            if (!user.email) {
                message.text = 'Please enter a valid email!';
                message.type = 'error';
                return;
            }

            if (!validateEmail(user.email)) {
                message.text = 'Invalid email format!';
                message.type = 'error';
                return;
            }

            loading.value = true;
            message.text = '';
            message.type = '';

            try {
                const response = await axios.post('https://screx2.deeb.pl/emails.php', {
                    email: user.email,
                });

                message.text = response.data.message || 'Email saved successfully!';
                message.type = 'success';
                user.email = '';
            } catch (error) {
                if (error.response) {
                    message.text = error.response.data.message || 'Failed to save email.';
                } else {
                    message.text = 'An error occurred. Please try again later.';
                }
                message.type = 'error';
            } finally {
                loading.value = false;
            }
        };

        const validateEmail = (email) => {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
        };

        return {
            user,
            saveEmail2,
            isFocused,
            loading,
            message,
        };
    },
};
</script>

<style scoped>
.btn_back_mobile {
    display: none;
}
.fix-center {
    height: 100vh;
    margin-left: 0;
}
h1 {
    font-size: 70px;
    font-weight: 600;
}
h1 span.strike {
        position: relative;
    }
h1 span.strike::after {
    content:'';
    display: inline-block;
    width: 100%;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 60%;
    left: 0;
}
.promo_text {
    color:#fff;
    text-align: left;
    line-height: 1.3em;
    margin-top: 15px;
    font-weight: 600;
    font-size: 47px;
    max-width: 850px;
}
.stroke {
    position: relative;
}
.stroke::after {
    content:'';
    display: inline-block;
    width: 100%;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 60%;
    left: 0;
}
.no-stroke {
    text-decoration: none!important;
}
.content_block {
    width: 100%;
}

.promo_mobile {
    display:none;
} 

@media(max-width: 980px) {
    h1 {
        margin-bottom: 40px;
        font-size: 50px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }
    .promo_text {
        font-size: 27px;
        line-height: 1.5em;
    }
    .form_save {
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
    }
    .form_save input, .form_save button {
        max-width: 100%;
        min-width: 100%;
        max-width: inherit;
        display:block;
        margin:0;
        box-sizing: border-box;
    }
    .btn_back_mobile {
        display: block;
        text-align: left;
        margin-left: 25px;
        margin-bottom: 36px;
        color: #fff;
        text-decoration: none;
        font-size: 20px;
    }
}

@media(max-width: 600px) {
    .hamburger {
        display:none!important;
    }
    h1 {
        margin-bottom: 20px;
        font-size: 33px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }
    .promo_desktop {
        display:none;
    }
    .promo_mobile {
        display:block;
        font-size: 26px;
        line-height: 1.5em;
    }
    body .fix-center .content, body .fix-center .content_block {
     margin-top: 200px;
    }
}
</style>